<div *ngIf="newsItems" class="w-full flex flex-col">
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngIf="isLeadAgency && user.stateId != 30 && isBeforeEndDate()">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill pillClass="WestEd">
            WestEd
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold">
          Locking DRDP Views
        </p>
      </div>
      <div class="overflow-x-auto scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class="relative overflow-x-auto">
          <div class="w-[91rem]">
            <p class="break-words mb-3">Lead Agency Administrators: It&#39;s time to lock in DRDP Assessment Views. To ensure report functionality and teacher access, any DRDP views not yet selected will be locked at their default settings on October 30, 2024. Contact the DRDP Online support team for any questions or guidance.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngFor="let news of newsItems">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill *ngIf="news.newsItemType.newsItemTypeName" [pillClass]="news.newsItemType.newsItemTypeName">
            {{ news.newsItemType.newsItemTypeName }}
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold mr-16">
          {{ news.newsItemTitle }}
          <drdp-btn *ngIf="news.createdBy === user.userId" class="mx-2 text-sm" (click)="editNewsItem(news)"
            cdkFocusInitial>
            <i class="uil uil-pen cursor-pointer"></i> Edit News Item
          </drdp-btn>
        </p>
      </div>
      <div class="flex py-4">
          <div>
            <p class=" break-words mb-3">{{ news.newsItemBody }}</p>
          </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!newsItems">
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngIf="isLeadAgency && user.stateId != 30 && isBeforeEndDate(); else noNewsMessage">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill pillClass="WestEd">
            WestEd
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold">
          Locking DRDP Views
        </p>
      </div>
      <div class="overflow-x-auto scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class="relative overflow-x-auto">
          <div class="w-[91rem]">
            <p class="break-words mb-3">Lead Agency Administrators: It&#39;s time to lock in DRDP Assessment Views. To ensure report functionality and teacher access, any DRDP views not yet selected will be locked at their default settings on October 30, 2024. Contact the DRDP Online support team for any questions or guidance.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #noNewsMessage>
    <p> Nothing new to see here!</p>
  </ng-template>
  
</div>
