<div class="p-5">
  <div>
    <drdp-title title="Download Demographics" i18n-title></drdp-title>
    <div>
      <p i18n>Select a rating period and classroom to generate a report for all the children in the classroom. 
        The report will display demographic information for the selected children. 
        The file will be available in your download folder.</p>
    </div>
  </div>

  <drdp-download-search (searchClicked)="downloadDemographics($event)"></drdp-download-search>
  </div>
