import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IAgency } from '@core/interfaces/iagency';
import { AgencyService } from '@core/services/agency.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-contractor',
  templateUrl: './select-contractor.component.html',
  styleUrls: ['./select-contractor.component.scss'],
})
export class SelectContractorComponent implements OnInit {
  @ViewChild('contractorSelect') contractorSelect: FilterSelectComponent | undefined;
  @Output() agency = new EventEmitter<any>();
  @Input() initialContractorId?: number | null;
  @Input() intialAgencyId?: number | null;
  @Input() stateId: number = 0;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = $localize `State Preschool Contractor Agency (if applicable)`;
  contractorOptions?: IAgency[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private agencyService: AgencyService) {}

  ngOnInit(): void {
    if (this.stateId) {
      this.getGranteeAgenciesByState(this.stateId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.contractorSelect?.clearSearch(true);
    }

    if (changes['stateId']) this.getGranteeAgenciesByState(this.stateId);
  }

  getGranteeAgenciesByState(stateId: number): void {
    if (this.stateId && this.stateId > 0) {
      this.agencyService
        .getContractorAgenciesByState(stateId)
        .pipe(take(1))
        .subscribe((agencies: IAgency[]) => {
          if (this.intialAgencyId !== undefined) {
            this.contractorOptions = agencies.filter(agency => agency.id !== this.intialAgencyId);
          } else {
            this.contractorOptions = agencies;
          }
        });
    } else {
      this.contractorOptions = [];
    }
  }

  onAgencySelect(event: IAgency): void {
    this.agency.emit(event);
  }
}
