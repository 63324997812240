<div>
    <div class="flex flex-row">
        <div>
            <h2 class="mb-2 mt-0 text-3xl leading-tight"><span i18n>Viewing Children in:</span> <b>{{classroomName}}</b></h2>
            <h2 class="mb-2 mt-0 text-2xl leading-tight"><span i18n>Rating Period:</span> <b>{{ratingPeriodName}}</b></h2>
        </div>
    </div>
    <drdp-container>
        <div class="p-5 w-full">
            <drdp-add-edit-table (toggleSwitchActionItem)="handleSelectedChild($event)"
                                 (viewActionItem)="reviewEvidence($event)"
                                  viewIcon="uil uil-notes text-drdpblue-300"
                                  [dataSource]="dataSource"
                                  [tableData]="tableData"
                                  [columns]="tableColumns"
                                  [checkboxSpace] = true>
            </drdp-add-edit-table>
        </div>

        <div class="border border-drdpred-200 rounded">
            <p i18n class="text-drdpred-200 p-2">
                *If Child's Age/Grade Instrument changes after evidence has been saved in current enrollment Portfolio, 
                evidence will be hidden.  If needed, please save the child's Portfolio evidence prior to changing their 
                demographic Age/Grade Instrument.
            </p>
        </div>
        <div *drdpCheckPermission="permission.EditObservations" class="mt-10 mb-2 flex justify-end">
            <drdp-btn i18n class="pr-4" color="cancel" type="button" (click)="onCancel()">
                Cancel
            </drdp-btn>
            <drdp-btn i18n type="submit" (click)="onNextButton()" cdkFocusInitial>
                Next
            </drdp-btn>
        </div>

    </drdp-container>

</div>
<mat-paginator class="pb-6" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable($event)">
</mat-paginator>
