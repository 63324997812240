<div class="fix-action-row modal-body">
    <div class="flex justify-between border-b">
      <h1 class="text-[25px] font-bold" i18n>
        The following portfolio evidence(s) cannot be shared.
      </h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
  
    <div mat-dialog-content>
        <h1 i18n class="mt-4">Please remove the following child(ren) from your approval request.</h1>
      <h1 i18n class="mt-4 font-bold" *ngIf="invalidRequests.isInvalidRatingPeriod?.length > 0"> 
        Child enrollment for the requested rating period has passed.
      </h1>
      <div class="mt-2">
        <ul>
          <li *ngFor="let student of invalidRequests.isInvalidRatingPeriod">
            - {{ student }}
          </li>
        </ul>
      </div>
      <h1 i18n class="mt-4 font-bold" *ngIf="invalidRequests.isDomainMeasureMismatch?.length > 0">
        Evidence cannot be copied due to a mismatch of age grade template domains and measures.
      </h1>
      <div class="mt-2">
        <ul>
          <li *ngFor="let student of invalidRequests.isDomainMeasureMismatch">
            - {{ student }}
          </li>
        </ul>
      </div>
    </div>
</div>
  
<div mat-dialog-actions class="flex justify-end">
    <drdp-btn i18n class="ml-3" mat-button cdkFocusInitial mat-dialog-close>OK</drdp-btn>
</div>
  