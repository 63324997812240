<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 i18n class="text-[25px] font-bold">
      Special Education Exit Date Update
    </h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <div i18n *ngIf="batchResults.isSuccess" class="mt-4"> The following children were updated with the selected exit date.</div>
    <div class="mt-2">
      <ul>
        <li *ngFor="let student of batchResults.successfulStudents">
          - <strong>{{ student }}</strong>
        </li>
      </ul>
    </div>
    <div i18n class="mt-4" *ngIf="batchResults.missingScoresStudents?.length > 0"> No scores found for the following children. Cannot proceed with an Exit Date.</div>
    <div class="mt-2">
      <ul>
        <li *ngFor="let student of batchResults.missingScoresStudents">
          - <strong>{{ student }}</strong>
        </li>
      </ul>
    </div>
    <div i18n class="mt-4" *ngIf="batchResults.incompleteCOSStudents?.length > 0"> The Outcome data on the Child's Demographics page is not complete. <br>
      All 3 outcomes must be chosen and saved. Cannot proceed with an Exit Date.</div>
    <div class="mt-2">
      <ul>
        <li *ngFor="let student of batchResults.incompleteCOSStudents">
          - <strong>{{ student }}</strong>
        </li>
      </ul>
    </div>
  </div>
</div>

<div mat-dialog-actions class="flex justify-end">
  <drdp-btn i18n class="ml-3" mat-button cdkFocusInitial mat-dialog-close>OK</drdp-btn>
</div>
