<div class="p-5">
    <div class="flex justify-between">
      <drdp-title title= {{title}}></drdp-title>
    </div>

    <drdp-student-demographics [state]="currentState"></drdp-student-demographics>

    <drdp-container additionClasses="h-min mt-5">
      <drdp-state-dashboard [stateId]="currentState?.id"></drdp-state-dashboard>
    </drdp-container>

    <drdp-container [additionClasses]="'h-min mt-5'">
      <drdp-child-upload [stateId]="currentState?.id"></drdp-child-upload>
    </drdp-container>

    <drdp-container [additionClasses]="'h-min mt-5'">

      <div class="w-full">
        <h1 i18n class="text-2xl font-medium">Custom Configurations</h1>
        <drdp-add-edit-table [dataSource]="dataSource"
                             [tableData]="tableData"
                             [columns]="tableColumns"
                             (editActionItem)="openModal($event)">
        </drdp-add-edit-table>

        <div class="col-span-2 w-[100%] h-min">
          <div class="flex justify-end w-[100%]"><drdp-btn (click)="openModal(null, currentState?.id)"><i class="uil uil-plus"></i><span i18n>Add Alternate Configuration</span></drdp-btn>
          </div>
      </div>

      </div>
    </drdp-container>

    <drdp-container [additionClasses]="'h-min mt-5'">
      <div class="w-full">
        <h1 i18n class="text-2xl font-medium">School Year OSEP Target</h1>
        <drdp-add-edit-table [dataSource]="dataSource" [tableData]="targetTableData" [columns]="targetColumns" 
          (editActionItem)="openTargetModal($event, currentState?.id)" (deleteActionItem)="deleteTargetModal($event)"> 
        </drdp-add-edit-table>
        <div class="col-span-2 w-[100%] h-min">
          <div class="flex justify-end w-[100%]">
            <drdp-btn  (click)="openTargetModal(null, currentState?.id)"><i class="uil uil-plus"></i>
              <span i18n>Add School Year OSEP Target</span></drdp-btn>
          </div>
        </div>
      </div>
    </drdp-container>

</div>
