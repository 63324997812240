<div class="p-5">
  <div class="flex justify-between">
    <drdp-title title="Ratings View Setup" i18n-title></drdp-title>
  </div>

  <div class="flex justify-between mb-2" *drdpCheckPermission="permission.CreateBaseTemplates">
    <h2 class="text-[20px] font-bold"></h2>
    <span (click)="gotToBaseForm()">
      <drdp-btn><i class="uil uil-plus"></i><span i18n>Add Base Template</span></drdp-btn>
    </span>
  </div>

  <drdp-container *ngIf="canSearch">
    <div class="w-full">
      <drdp-search [searchInput]="searchInput" (search)="getEnrollmentsByAgencyId($event)"
        (clear)="clearForm()"></drdp-search>
    </div>
  </drdp-container>
  <drdp-container [additionClasses]="canSearch ? 'mt-5' : ''">
    <h2 i18n class="text-[20px] font-bold mb-2">Select Agency Default Views</h2>
    <hr>
    <div i18n *ngIf="!canViewResults" class="mt-4"> Select an agency to display. </div>
    <form [formGroup]="defaultViewForm" class="mt-4" *ngIf="canViewResults">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-6">
        <div class="form-input-label-group" *ngIf="hasIT">
          <label class="font-normal required" for="itTemplateId">IT</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="itTemplateId" placeholder="Select an option" i18n-placeholder
              [disabled]="!canEditRatingViews || !!agency?.isRatingViewLocked">
              <mat-option *ngFor="let option of itOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group" *ngIf="hasPS">
          <label class="font-normal required" for="psTemplateId">PS</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="psTemplateId" placeholder="Select an option"
            i18n-placeholder
              [disabled]="!canEditRatingViews || !!agency?.isRatingViewLocked">
              <mat-option *ngFor="let option of psOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group" *ngIf="hasTK">
          <label class="font-normal required" for="tkTemplateId">TK</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="tkTemplateId" placeholder="Select an option"
            i18n-placeholder
              [disabled]="!canEditRatingViews || !!agency?.isRatingViewLocked">
              <mat-option *ngFor="let option of tkOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group" *ngIf="hasK">
          <label class="font-normal required" for="kTemplateId">K</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="kTemplateId" placeholder="Select an option"
            i18n-placeholder
              [disabled]="!canEditRatingViews || !!agency?.isRatingViewLocked">
              <mat-option *ngFor="let option of kOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group" *ngIf="hasSA">
          <label class="font-normal required" for="saTemplateId">SA</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="saTemplateId" placeholder="Select an option"
            i18n-placeholder
              [disabled]="!canEditRatingViews || !!agency?.isRatingViewLocked">
              <mat-option *ngFor="let option of saOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="mt-4 mb-2 flex justify-end">
        <drdp-btn i18n (click)="submitForm()"
          [disabled]="!canEditRatingViews || defaultViewForm.invalid || agency?.isRatingViewLocked"> Save and Lock
        </drdp-btn>
        <drdp-btn i18n class="ml-2" (click)="unlockAgency()" *ngIf="canUnlockRatingViews && agency?.isRatingViewLocked">
          Unlock
        </drdp-btn>
      </div>
    </form>
  </drdp-container>

  <drdp-container *ngIf="selectedStateId == states.california" [additionClasses]="canSearch ? 'mt-5' : ''">
    <h2 i18n class="text-[20px] font-bold mb-2">DRDP 2025 Pilot Views</h2>
    <hr>
    <div i18n *ngIf="!canViewResults" class="mt-4"> Select an agency to display.</div>
    <form [formGroup]="pilotViewForm" class="mt-4" *ngIf="canViewResults">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-6">
        <div class="form-input-label-group">
          <label class="font-normal required" for="pilotItTemplateId">IT</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="pilotItTemplateId" placeholder="Select an option" i18n-placeholder [disabled]="true">
              <mat-option *ngFor="let option of pilotItOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group">
          <label class="font-normal required" for="ptkTemplateId">PTK</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="ptkTemplateId" placeholder="Select an option" i18n-placeholder [disabled]="true">
              <mat-option *ngFor="let option of ptkOptions" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-label-group">
          <label class="font-normal required" for="ptk3TemplateId">PTK-3</label>
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select formControlName="ptk3TemplateId" placeholder="Select an option" i18n-placeholder [disabled]="true">
              <mat-option *ngFor="let option of ptk3Options" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </drdp-container>

  <drdp-container additionClasses="mt-5">
    <div class="flex justify-between mb-2">
      <h2 class="text-[20px] font-bold" i18n>Alternate Rating Views</h2>
      <span (click)="addCustomRatingModal()" *ngIf="canViewResults">
        <drdp-btn *drdpCheckPermission="permission.CreateRatingViews"><i class="uil uil-plus"></i> <span i18n>Add Alternate Rating
          View</span></drdp-btn>
      </span>
    </div>
    <hr>
    <div i18n *ngIf="!canViewResults" class="mt-4">Select an agency to display.</div>
    <div *ngIf="canViewResults">
      <drdp-add-edit-table class="left-justified" [dataSource]="dataSource" [tableData]="customTemplates"
        [columns]="customTableColumns" viewIcon="uil uil-file text-drdpblue-300"
        (viewActionItem)="viewCustomForm($event)" (deleteActionItem)="deleteModal($event)">>
      </drdp-add-edit-table>
    </div>
  </drdp-container>
</div>