import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AgeGradeInstrument,
  AgeGradeInstrumentCode,
} from '@core/enums/age-grade-instruments';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { forkJoin, take, tap } from 'rxjs';
import { IRatingBaseTemplate } from './interfaces/irating-base-template';
import { IRatingViewEnrollment } from './interfaces/irating-view-enrollment';
import { Permission } from '@core/enums/permissions';
import { AgeGradeTemplateService } from '@core/services/age-grade-template.service';
import { AgeGradeTemplateEnrollmentService } from '@core/services/age-grade-template-enrollment.service.ts';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumnType } from '@core/enums/table';
import { ICustomRatingTemplate } from './interfaces/icustom-rating-template';
import { PermissionService } from '@core/services/permission.service';
import { AgencyService } from '@core/services/agency.service';
import { IAgency } from '@core/interfaces/iagency';
import { LookupService } from '@core/services/lookup.service';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { IModalEmitData } from '@core/interfaces/imodal';
import { Subscription } from 'rxjs';
import { ClassService } from '@core/services/class.service';
import { States } from '@core/enums/states';

@Component({
  selector: 'drdp-rating-view-setup',
  templateUrl: './rating-view-setup.component.html',
  styleUrls: ['./rating-view-setup.component.scss'],
})
export class RatingViewSetupComponent implements OnInit {
  defaultViewForm: FormGroup | any;
  pilotViewForm: FormGroup | any;
  psOptions: IRatingBaseTemplate[] = [];
  itOptions: IRatingBaseTemplate[] = [];
  kOptions: IRatingBaseTemplate[] = [];
  saOptions: IRatingBaseTemplate[] = [];
  tkOptions: IRatingBaseTemplate[] = [];
  pilotItOptions: IRatingBaseTemplate[] = [];
  ptkOptions: IRatingBaseTemplate[] = [];
  ptk3Options: IRatingBaseTemplate[] = [];
  hasIT = false;
  hasPS = false;
  hasK = false;
  hasSA = false;
  hasTK = false;
  selectedAgencyId = 0;
  selectedStateId = 0;
  ageGradeInstrument = AgeGradeInstrument;
  permission = Permission;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  customTemplates: ICustomRatingTemplate[] = [];
  ageGradeInstruments: IAgeGradeInstrument[] = [];
  canEditRatingViews = false;
  canUnlockRatingViews = false;
  agency: IAgency | null = null;
  currentUser?: ILoginResponse;
  dialog = inject(MatDialog);
  ratingPeriodService = inject(RatingPeriodService);
  schoolYearName: string = '';
  pilotAgeGradeInstruments: IAgeGradeInstrument[] = [];
  states = States;

  customTableColumns = [
    {
      columnDef: 'name',
      header: $localize `Title`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'ratingPeriod',
      header:$localize `Rating Period`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'ageGradeInstrument',
      header: $localize `Age Group`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'description',
      header: $localize `Description`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'baseDescription',
      header: $localize `Base Template`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'View',
      header: $localize `View`,
      type: TableColumnType.view,
    }
  ];

  get itTemplateId() {
    return this.defaultViewForm.get('itTemplateId');
  }
  get psTemplateId() {
    return this.defaultViewForm.get('psTemplateId');
  }

  get tkTemplateId() {
    return this.defaultViewForm.get('tkTemplateId');
  }
  get kTemplateId() {
    return this.defaultViewForm.get('kTemplateId');
  }

  get saTemplateId() {
    return this.defaultViewForm.get('saTemplateId');
  }

  constructor(
    public modal: MatDialog,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private templateService: AgeGradeTemplateService,
    private templateEnrollmentService: AgeGradeTemplateEnrollmentService,
    private toast: ToastService,
    private router: Router,
    private permissionService: PermissionService,
    private lookupService: LookupService,
    private classService: ClassService,
    public PermissionService: PermissionService
  ) {
    this.currentUser = this.authService.getCurrentUser();
    this.selectedAgencyId =
      this.router.getCurrentNavigation()?.extras?.state?.['agencyId'] ||
      this.currentUser.agencyId;
    this.selectedStateId =
      this.router.getCurrentNavigation()?.extras?.state?.['stateId'] ||
      this.currentUser.stateId;
  }
  private subscriptions = new Subscription();
  canDelete = this.PermissionService.checkPermission(Permission.DeleteRatingView);
  receivedPayload?: any;

  canSearch = false;
  canViewResults = true;
  searchInput: ISearchInput = {
    searchName: false,
    searchAgency: true,
    searchState: true,
  };
  agencyBaseEnrollments: IRatingViewEnrollment[] = [];

  ngOnInit(): void {
    this.canSearch =
      this.authService.isSuperAdmin() || this.authService.isCustomerService();
    this.canViewResults = !this.canSearch;

    this.canEditRatingViews = this.permissionService.checkPermission(
      Permission.EditRatingViews
    );

    this.canUnlockRatingViews = this.permissionService.checkPermission(
      Permission.UnlockRatingViews
    );
    this.initForm();
    this.initData();

    if (this.canDelete) {
      this.customTableColumns.push({ columnDef: 'delete', header: $localize `Delete`, type: TableColumnType.delete })
    }
  }

  initForm() {
    this.defaultViewForm = this.formBuilder.group({
      itTemplateId: [{ value: null, disabled: !this.canEditRatingViews }],
      psTemplateId: [{ value: null, disabled: !this.canEditRatingViews }],
      kTemplateId: [{ value: null, disabled: !this.canEditRatingViews }],
      saTemplateId: [{ value: null, disabled: !this.canEditRatingViews }],
      tkTemplateId: [{ value: null, disabled: !this.canEditRatingViews }],
    });

    this.pilotViewForm = this.formBuilder.group({
      pilotItTemplateId: [null],
      ptkTemplateId: [null],
      ptk3TemplateId: [null],
    });
  }

  initData() {
    this.ratingPeriodService
      .getActiveSchoolYear()
      .pipe(take(1))
      .subscribe((res) => {
        this.schoolYearName = res.schoolYearName;
      });

    if (this.canViewResults) {
      this.getBaseTemplates(0);
      this.getAgencyTemplates(0);
      this.getAgeGradeInstruments(0);
    }
  }

  getBaseTemplates(stateId: number) {
    this.templateService
      .getBaseTemplates(stateId)
      .pipe(
        take(1),
        tap((results) => {
          this.psOptions = results.filter(
            (r) => r.ageGradeInstrumentCode == AgeGradeInstrumentCode.preschool
          );
          this.itOptions = results.filter(
            (r) =>
              stateId == States.newHampshire ?
                r.ageGradeInstrumentId == AgeGradeInstrument.nhInfantToddler :
                r.ageGradeInstrumentId == AgeGradeInstrument.infantToddler
          );
          this.kOptions = results.filter(
            (r) =>
              r.ageGradeInstrumentCode == AgeGradeInstrumentCode.kindergarten
          );
          this.saOptions = results.filter(
            (r) => r.ageGradeInstrumentCode == AgeGradeInstrumentCode.schoolAge
          );
          this.tkOptions = results.filter(
            (r) =>
              r.ageGradeInstrumentCode ==
              AgeGradeInstrumentCode.transitionalKinder
          );

          if (this.selectedStateId == States.california) {
            this.pilotItOptions = results.filter(
              (r) =>
                r.ageGradeInstrumentId == AgeGradeInstrument.pilotInfantToddler
            );
            this.ptkOptions = results.filter(
              (r) =>
                r.ageGradeInstrumentCode == AgeGradeInstrumentCode.pilotPreschool
            );
            this.ptk3Options = results.filter(
              (r) =>
                r.ageGradeInstrumentCode == AgeGradeInstrumentCode.pilotPreschool3
            );
          }
        })
      )
      .subscribe();
  }

  getAgencyInfo(agencyId: number) {
    this.agencyService
      .getAgency(agencyId)
      .pipe(take(1))
      .subscribe((agency) => {
        this.agency = agency;
      });
  }

  unlockAgency() {
    this.agencyService
      .unlockAgency(this.selectedAgencyId)
      .pipe(take(1))
      .subscribe((res) => {
        this.getAgencyInfo(this.selectedAgencyId);
      });
  }

  getEnrollmentsByAgencyId(search: any): void {
    if (search.StateId) {
      this.selectedStateId = search.StateId;
      this.getBaseTemplates(search.StateId);
      this.getAgeGradeInstruments(search.StateId);
    }

    if (search.AgencyId) {
      this.canViewResults = true;
      this.selectedAgencyId = search.AgencyId;
      this.getAgencyTemplates(search.AgencyId);
    }
  }

  getAgeGradeInstruments(stateId: number) {
    this.subscriptions.add(
      forkJoin({
        ageGradeInstrument: this.lookupService.getAgeGradeInstruments(stateId),
        pilotAgeGradeInstrument: this.lookupService.getPilotAgeGrade(),
      }).subscribe(({ ageGradeInstrument, pilotAgeGradeInstrument }) => {
        this.ageGradeInstruments = ageGradeInstrument;
        this.pilotAgeGradeInstruments = pilotAgeGradeInstrument;
        this.updateAgeGradeInstruments();
      }
    ));
  }

  updateAgeGradeInstruments() {
    this.hasIT =
      this.ageGradeInstruments.find(
        (agi) =>
          agi.ageGradeInstrumentCode == AgeGradeInstrumentCode.infantToddler
      ) != null;

    this.hasSA =
      this.ageGradeInstruments.find(
        (agi) => agi.ageGradeInstrumentCode == AgeGradeInstrumentCode.schoolAge
      ) != null;

    this.hasPS =
      this.ageGradeInstruments.find(
        (agi) => agi.ageGradeInstrumentCode == AgeGradeInstrumentCode.preschool
      ) != null;

    this.hasTK =
      this.ageGradeInstruments.find(
        (agi) =>
          agi.ageGradeInstrumentCode ==
          AgeGradeInstrumentCode.transitionalKinder
      ) != null;

    this.hasK =
      this.ageGradeInstruments.find(
        (agi) =>
          agi.ageGradeInstrumentCode == AgeGradeInstrumentCode.kindergarten
      ) != null;

    if (this.hasIT) {
      this.itTemplateId.setValidators(Validators.required);
    } else {
      this.itTemplateId.clearValidators();
    }

    if (this.hasPS) {
      this.psTemplateId.setValidators(Validators.required);
    } else {
      this.psTemplateId.clearValidators();
    }

    if (this.hasK) {
      this.kTemplateId.setValidators(Validators.required);
    } else {
      this.kTemplateId.clearValidators();
    }

    if (this.hasSA) {
      this.saTemplateId.setValidators(Validators.required);
    } else {
      this.saTemplateId.clearValidators();
    }

    if (this.hasTK) {
      this.tkTemplateId.setValidators(Validators.required);
    } else {
      this.tkTemplateId.clearValidators();
    }

    this.defaultViewForm.updateValueAndValidity();
  }

  getAgencyTemplates(agencyId: number) {
    this.getAgencyInfo(agencyId);
    this.templateEnrollmentService
      .getBaseTemplateEnrollmentByAgency(agencyId)
      .pipe(
        take(1),
        tap((results) => {
          this.updateFormValue(results);
        })
      )
      .subscribe();

    this.templateService
      .getCustomTemplatesByAgencyId(agencyId)
      .pipe(
        take(1),
        tap((results) => {
          this.customTemplates = results;
          this.dataSource = new MatTableDataSource(results);
        })
      )
      .subscribe();
  }

  updateFormValue(results: IRatingViewEnrollment[]) {
    this.defaultViewForm.reset();
    if (results) {
      this.agencyBaseEnrollments = results.filter((r) => !r.isCustom);
      const psOption = this.agencyBaseEnrollments.find(
        (a) => a.ageGradeInstrumentCode == AgeGradeInstrumentCode.preschool
      )?.ageGradeTemplateId;
      const itOption = this.agencyBaseEnrollments.find(
        (a) => 
          this.selectedStateId == States.newHampshire ?
            a.ageGradeInstrumentId == AgeGradeInstrument.nhInfantToddler :
            a.ageGradeInstrumentId == AgeGradeInstrument.infantToddler
      )?.ageGradeTemplateId;
      const kOption = this.agencyBaseEnrollments.find(
        (a) => a.ageGradeInstrumentCode == AgeGradeInstrumentCode.kindergarten
      )?.ageGradeTemplateId;
      const saOption = this.agencyBaseEnrollments.find(
        (a) => a.ageGradeInstrumentCode == AgeGradeInstrumentCode.schoolAge
      )?.ageGradeTemplateId;
      const tkOption = this.agencyBaseEnrollments.find(
        (a) =>
          a.ageGradeInstrumentCode == AgeGradeInstrumentCode.transitionalKinder
      )?.ageGradeTemplateId;

      this.defaultViewForm.setValue({
        psTemplateId: psOption ?? null,
        itTemplateId: itOption ?? null,
        kTemplateId: kOption ?? null,
        saTemplateId: saOption ?? null,
        tkTemplateId: tkOption ?? null,
      });

      if (this.selectedStateId == States.california) {
        const pilotItOption = this.agencyBaseEnrollments.find(
          (a) =>
            a.ageGradeInstrumentId == AgeGradeInstrument.pilotInfantToddler
        )?.ageGradeTemplateId;
        const ptkOption = this.agencyBaseEnrollments.find(
          (a) =>
            a.ageGradeInstrumentCode == AgeGradeInstrumentCode.pilotPreschool
        )?.ageGradeTemplateId;
        const ptk3Option = this.agencyBaseEnrollments.find(
          (a) =>
            a.ageGradeInstrumentCode == AgeGradeInstrumentCode.pilotPreschool3
        )?.ageGradeTemplateId;

        this.pilotViewForm.setValue({
          pilotItTemplateId: pilotItOption ?? null,
          ptkTemplateId: ptkOption ?? null,
          ptk3TemplateId: ptk3Option ?? null,
        });
      }
    }
  }

  submitForm() {
    if (this.agency?.isRatingViewLocked) return;
    if (this.defaultViewForm.invalid) {
      this.toast.error($localize `Please enter all required fields.`);
      return;
    }

    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `Confirmation`,
      message: $localize `The chosen Rating Views are for the ` + `${this.schoolYearName}` +  $localize ` school year and will be locked until the next school year.`,
      primaryBtnClass: 'blue',
      primaryBtnText: this.translateOk(Modal.OK),
      showCancel: true,
    };
    const modalRef = this.dialog.open(ConfirmationModalComponent, {
      data: event,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) this.save();
    });
  }

  save() {
    const formValue = this.defaultViewForm.value;
    let request = {
      agencyId: this.selectedAgencyId,
      templateIds: [
        formValue.psTemplateId,
        formValue.itTemplateId,
        formValue.kTemplateId,
        formValue.saTemplateId,
        formValue.tkTemplateId,
      ],
    };

    request.templateIds = request.templateIds.filter((t) => t != null);
    this.templateEnrollmentService
      .saveBaseTemplateEnrollment(request)
      .pipe(take(1))
      .subscribe((res) => {
        this.getAgencyInfo(this.selectedAgencyId);
      });
  }

  addCustomRatingModal() {
    var event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `Add Alternate Rating View`,
      message: $localize `Please note that your site, classroom, and children must already exist in DRDP Online prior to assigning a alternate rating view.`,
      primaryBtnClass: 'blue',
      primaryBtnText: this.translateOk(Modal.OK),
      showCancel: true,
    };
    const modalRef = this.dialog.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.goToCustomForm();
      }
    });
  }

  goToCustomForm() {
    this.router.navigateByUrl('/config/custom-rating-view', {
      state: { agencyId: this.selectedAgencyId, stateId: this.selectedStateId },
    });
  }

  gotToBaseForm() {
    this.router.navigateByUrl('/config/base-rating-view', {
      state: { agencyId: this.selectedAgencyId, stateId: this.selectedStateId },
    });
  }

  viewCustomForm(data: any) {
    this.router.navigateByUrl(`/config/custom-rating-view/${data.data.id}`, {
      state: { agencyId: this.selectedAgencyId, stateId: this.selectedStateId },
    });
  }

  clearForm() {
    this.canViewResults = false;
  }

  translateEnum(enumValue: any): string {
    return $localize `${enumValue}`
  }

  translateOk(ok: any): string {
    switch (ok) {
      case Modal.OK: 
        return $localize `Ok`;
      default:
        return '';
    }
  }

  deleteModal(event: IModalEmitData): void {
    const customTemplateId = event.data.id;
    if (event.data.hasOwnProperty('ratingPeriod')) {
      event.data.modalInfo = {
        title: `${event.data.name}` + $localize `cannot be deleted`,
        message: $localize `This alternate rating view has been assigned and can not be deleted`,
        name: event.data.name,
        primaryBtnClass: 'blue',
        primaryBtnText: this.translateOk(Modal.OK),
      };
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe();
    } else {
      event.data.modalInfo = {
        title: $localize `Delete ` + `${event.data.name}`,
        message: $localize `Are you sure you want to want to delete ` + `<span class="font-bold">${event.data.name}</span>?`,
        name: event.data.name,
        primaryBtnClass: 'red',
        primaryBtnText: this.translateEnum(Modal.Delete),
        showCancel: true,
      };

      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.templateService
            .deleteAgeGradeTemplate(customTemplateId)
            .subscribe((res: boolean) => {
              if (res) {
                this.toast.success(
                  `${event.data.name}` + $localize ` successfully deleted!`
                );
                this.dataSource.data = this.dataSource.data.filter(
                  (item: any) => item.id !== event.data
                );
              } else {
                this.toast.error(
                  $localize `Failed to delete ` + `${event.data.name}.` + $localize ` Please try again.`
                );
              }
              this.initData();
            });
        }
      });
    }
  }
}
