import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClassDeclineReleaseReason } from "@core/enums/student-request-reason";
import { LookupService } from "@core/services/lookup.service";
import { StudentRequestService } from "@core/services/student-request.service";
import { ToastService } from "@core/services/toast.service";
import { Observable, take } from "rxjs";


@Component({
    selector: 'drdp-decline-release-student',
    templateUrl: './decline-release-student.component.html',
    styleUrls: ['./decline-release-student.component.scss'],
})
export class DeclineReleaseStudentComponent implements OnInit {
    child: any;
    declineReleaseStudentForm: FormGroup | any;
    declineReleaseReasons$?: Observable<any>;
    isOther: boolean = false;
    declineReleaseReasonName: string = '';

    get classReleaseReasonId() {
        return this.declineReleaseStudentForm.get('classDeclineReleaseReasonId');
    }
    get comment() {
        return this.declineReleaseStudentForm.get('comment');
    }

    constructor(
        public dialogRef: MatDialogRef<DeclineReleaseStudentComponent>,
        private lookup: LookupService,
        private toastService: ToastService,
        private studentRequestService: StudentRequestService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.initializeForm();
        this.getDeclineReleaseReasons();
    }

    initializeForm(): void {
        this.declineReleaseStudentForm = this.fb.group({
            classDeclineReleaseReasonId: [null, Validators.required],
            comment: [null],
        });

        this.child = this.data;
    }

    getDeclineReleaseReasons() {
        this.declineReleaseReasons$ = this.lookup.getClassDeclineReleaseReasons();
    }

    getSelectedDeclineReleaseReason(event: any) {
        if (event.id === ClassDeclineReleaseReason.Other) {
            this.isOther = true;
        }
        else {
            this.isOther = false;
            this.comment.setValue(null);
        }

        this.declineReleaseReasonName = event?.declineReleaseReason;
    }

    onSubmit() {
        if (this.declineReleaseStudentForm.invalid) {
            this.toastService.error($localize `Please enter all required fields.`);
            return;
        }

        let data: any = this.declineReleaseStudentForm.value;
        data.declineReleaseReasonName = this.declineReleaseReasonName;

        this.dialogRef.close({ success: true, data: data });
    }

}