<div class="pl-5 pb-5 w-full">
  <h1 i18n class="text-2xl font-medium">State Dashboard</h1>
  <form id="dashboardForm" class="grid grid-cols-2 gap-1 pt-4" [formGroup]="dashboardForm">
    <div class="col-span-1 items-center w-[100%]">
      <label i18n class="font-bold" for="Name">Tile Name</label>
    </div>

    <div class="col-span-1 items-center w-[100%]">
      <label i18n class="font-bold" for="Options">Tile Status</label>
    </div>

    <ng-container *ngFor="let tile of dashboardTiles">
      <div class="flex flex-col justify-center w-[100%]">
        <label class="font-normal" [for]="tile.controlName">{{ tile.title }}</label>
      </div>
      <div class="flex flex-col w-[100%] h-min mt-1.5">
        <mat-checkbox i18n [formControlName]="tile.controlName" color="primary">Enable</mat-checkbox>
      </div>
    </ng-container>

    <div class="col-span-2 w-[100%] h-min">
      <div class="flex justify-end w-[100%]">
        <drdp-btn i18n color="cancel" (click)="onCancel()">Cancel</drdp-btn>
        <span i18n class=" ml-5"><drdp-btn (click)="onSubmit()" [disabled]="isSubmitting">Save and Submit</drdp-btn></span>
      </div>
  </div>
  </form>
</div>
