<div class="p-5">
  <div class="mb-5 mr-3">
    <drdp-title title="Pending Portfolio Evidence Requests" i18n-title></drdp-title>
  </div>
  <drdp-container additionClasses="flex flex-wrap content-between justify-end min-h-[44rem]">
    <div class="w-full">
      <drdp-container color="lightBlue" additionClasses="min-h-[7rem] w-full">
        <div
          class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center">
          <div class="col-span-1 self-end">
            <drdp-select-state #stateSelect [initialStateId]="stateSearchId" (state)="handleState($event)" [clear]="clearDropdowns">
            </drdp-select-state>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-agency #agencySelect [initialAgencyId]="agencySearchId" (agency)="handleAgency($event)"
              [stateId]="stateSearchId" [clear]="clearDropdowns">
            </drdp-select-agency>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-rating-period [initialRatingPeriodId]="searchPayload.toRatingPeriodId" label="Rating Period" i18n-label (ratingPeriod)="handleToRatingPeriod($event)" [agencyId]="agencySearchId"
              [clear]="clearDropdowns">
            </drdp-select-rating-period>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site [initialSiteId]="searchPayload.fromSiteId" label="Previous Site" i18n-label (site)="handleFromSites($event)" [agencyId]="agencySearchId" [clear]="clearDropdowns"
              [multiSelect]="false" [multiSelectInitVals]="false">
            </drdp-select-site>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site [initialSiteId]="searchPayload.toSiteId" label="Current Site" i18n-label (site)="handleToSites($event)" [agencyId]="agencySearchId"
              [clear]="clearDropdowns">
            </drdp-select-site>
          </div>
          <div class="col-span-1 self-end">
            <drdp-filter-select #classSelect label="Previous Classroom" i18n-label [options]="fromClassroomList" [selectType]="selectClass"
              [intialValues]="fromClassId" (onSelected)="handleFromClassroom($event)">
            </drdp-filter-select>
          </div>
          <div class="col-span-1 self-end">
            <drdp-filter-select label="Current Classroom" i18n-label [options]="toClassroomList" [selectType]="selectClass"
              [intialValues]="toClassId" (onSelected)="handleToClassroom($event)">
            </drdp-filter-select>
          </div>

          <div class="hidden lg:block lg:col-span-1 xl:col-span-1 self-end"></div>
          <div class="hidden lg:block lg:col-span-1 xl:col-span-1 self-end"></div>
          <div class="flex flex-row justify-evenly lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-2 md:mt-0">
            <div class="col-span-1">
              <drdp-btn (click)="search()">
                <span i18n class="px-2">Search</span>
              </drdp-btn>
            </div>
            <div class="col-span-1">
              <drdp-btn i18n color="outline" (click)="onClear()"> Clear </drdp-btn>
            </div>
          </div>
        </div>
      </drdp-container>
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns" (toggleSwitchActionItem)="handleRequest($event)">
      </drdp-add-edit-table>
    </div>
    <div mat-dialog-actions class="flex flex-wrap content-between align-bottom">
      <drdp-btn i18n color="cancel" class="mr-3" mat-button (click)="handleCancel()">
        Cancel
      </drdp-btn>
      <drdp-btn i18n mat-button (click)="handleSubmit()" [disabled]="isCurrentlyEditing || !isSubmitBtnEnabled()"
        cdkFocusInitial>
        Save and Submit
      </drdp-btn>
    </div>
  </drdp-container>
</div>

