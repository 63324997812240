<drdp-container additionClasses="h-min">

    <div class="pl-5 pb-5 w-full">
        <h1 i18n class=" text-2xl font-medium">Student Demographics</h1>
        <form id="demographicForm" class="grid grid-cols-2 gap-1 pt-4" [formGroup]="demographicForm">
            <div class="col-span-1 items-center invisible lg:visible w-[100%]">
              <label i18n class="font-bold" for="Name">Name</label>
            </div>

            <div class="col-span-1 items-center invisible lg:visible w-[100%]">
              <label i18n class="font-bold" for="Options">Options</label>
            </div>

            <div class="col-span-2 lg:col-span-1 pt-5 items-center w-[100%]">
              <label i18n class="font-normal" for="Statewide Student Identifier">Statewide Student Identifier</label>
            </div>

            <div class="col-span-2 lg:col-span-1 pt-5 w-[100%]">
              <div class="grid col-span-1 sm:grid-cols-3 gap-3">
                <div class="col-span-1">
                  <mat-form-field id="ssidLabel" class="bg-white">
                      <input matInput
                             autocomplete="off"
                             placeholder="SSID Label"
                             i18n-placeholder
                             id="SSID"
                             type="text"
                             [formControlName]="studentDemograpic.SSID"
                             maxlength="100">
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                  <mat-form-field id="ssidAgency" class="bg-white">
                      <input matInput
                             autocomplete="off"
                             placeholder="SSID Max Length"
                             i18n-placeholder
                             id="customMaxLengthSSID"
                             type="number"
                             [formControlName]="studentDemograpic.maxLength"
                             >
                    </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-span-2 lg:col-span-1 items-center w-[100%]">
              <label i18n class="font-normal" for="Statewide Student Identifier">Agency/District Student Identifier</label>
            </div>

            <div class="col-span-2 lg:col-span-1 w-[100%]">
              <div class="grid col-span-1 sm:grid-cols-3 gap-4">
                <div class="col-span-1">
                  <mat-form-field class="bg-white">
                      <input matInput
                             autocomplete="off"
                             placeholder="Agency SID Label"
                             i18n-placeholder
                             id="ADSI"
                             type="text"
                             [formControlName]="studentDemograpic.AgencySID"
                             maxlength="100">
                    </mat-form-field>
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Primary Disability">Region Label</label>
            </div>

            <div class="flex flex-col w-[100%] lg:w-[35%] h-min">
              <mat-form-field id="fieldTypeAgency" floatLabel="never">
                <mat-select matNativeControl placeholder="Select Label" [formControlName]="studentDemograpic.RegionLabel" >
                  <mat-option i18n [value]="regionTypeConst.Region">Region</mat-option>
                  <mat-option i18n [value]="regionTypeConst.SAU">SAU</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Primary Disability">Primary Disability</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.PrimaryDisability"
                              color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Special Education Entry Date">Special Education Entry Date</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.SpecialEdEntryDate"
                               color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="District of Liability">District of Liability</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.DistrictLiability"
                              color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Anticipated Date of Exit from Preschool Education to Kindergarten">Anticipated Date of Exit from Preschool Education to Kindergarten</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.AnticipatedDateExit"
                              color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Date of Exit from Preschool Special Education to Kindergarten">Date of Exit from Preschool Special Education to Kindergarten</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.DateExit"
                               color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label class="font-normal" for="Selpa">Selpa</label>
            </div>

            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.Selpa"
                               color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="InitialAssessmentTool">Initial Assessment Tool</label>
            </div>
            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.initialAssessmentToolId"
                  color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Outcome 1">Outcome 1</label>
            </div>
            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.outcome1Id"
                  color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Outcome 2">Outcome 2</label>
            </div>
            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.outcome2Id"
                  color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="flex flex-col justify-center w-[100%]">
              <label i18n class="font-normal" for="Outcome 3">Outcome 3</label>
            </div>
            <div class="flex flex-col w-[100%] h-min">
                <mat-checkbox i18n [formControlName]="studentDemograpic.outcome3Id"
                  color="primary">
                  Enable
                </mat-checkbox>
            </div>

            <div class="col-span-2 w-[100%] h-min">
                <div class="flex justify-end w-[100%]">
                    <drdp-btn i18n [color]="'cancel'" (click)="backToStatePage()">Cancel</drdp-btn>
                    <span class=" ml-5"><drdp-btn i18n (click)="onSubmit()">Save and Submit</drdp-btn></span>
                </div>
            </div>

          </form>
    </div>
</drdp-container>
