<div class="p-5">
  <div>
    <drdp-title title="Download Demographics Include Ratings" i18n-title></drdp-title>
    <div>
      <p i18n>Select a rating period and classroom to generate a report for all the children in the classroom. 
        The report will display demographic and ratings information for the selected children. 
        The file will be sent to the email associated with the user account. </p>
    </div>
  </div>
  <drdp-download-search (searchClicked)="downloadDemographics($event)"></drdp-download-search>
</div>