import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { IRatingBaseTemplate } from '../../interfaces/irating-base-template';
import { AgeGradeTemplateService } from '@core/services/age-grade-template.service';
import { ToastService } from '@core/services/toast.service';
import { IBaseTemplateDomain } from '../../interfaces/ibase-template-domain';
import { ICustomRatingTemplateForm } from '../../interfaces/icustom-rating-template-form';
import { take, tap, combineLatest, Observable, Subscription } from 'rxjs';
import { LookupService } from '@core/services/lookup.service';
import { AgeGradeTemplateEnrollmentService } from '@core/services/age-grade-template-enrollment.service.ts';
import { IRatingViewEnrollment } from '../../interfaces/irating-view-enrollment';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { MatDialog } from '@angular/material/dialog';
import { ReassignModalComponent } from './reassign-modal/reassign-modal.component';

@Component({
  selector: 'drdp-custom-rating-view-form',
  templateUrl: './custom-rating-view-form.component.html',
  styleUrls: ['./custom-rating-view-form.component.scss'],
})
export class CustomRatingViewFormComponent implements OnInit, OnDestroy {
  id: number = 0;
  pageTitle = $localize `Custom Rating View`;
  isView = false;
  customViewForm: FormGroup | any;
  ageGradeInstruments: IAgeGradeInstrument[] = [];
  baseTemplates: IRatingBaseTemplate[] = [];
  ageGradeBaseTemplates: IRatingBaseTemplate[] = [];
  templateDomains: IBaseTemplateDomain[] = [];
  selectedAgencyId = 0;
  selectedStateId = 0;
  nextLabel = $localize `Next`;
  customTemplate?: ICustomRatingTemplateForm;
  customAssignments: IRatingViewEnrollment[] = [];
  hasAssignments = false;
  siteAssignments: IRatingViewEnrollment[] = [];
  classAssignments: IRatingViewEnrollment[] = [];
  studentAssignments: IRatingViewEnrollment[] = [];
  canEditRatingViews = false;
  lookups$?: Observable<{
    ageGradeInstruments: IAgeGradeInstrument[];
    baseTemplates: IRatingBaseTemplate[];
  }>;
  subscriptions = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private lookupService: LookupService,
    private templateService: AgeGradeTemplateService,
    private templateEnrollmentService: AgeGradeTemplateEnrollmentService,
    private permissionService: PermissionService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.selectedAgencyId =
      this.router.getCurrentNavigation()?.extras?.state?.['agencyId'] || 0;
    this.selectedStateId =
      this.router.getCurrentNavigation()?.extras?.state?.['stateId'] || 0;
  }

  get title() {
    return this.customViewForm.get('title');
  }
  get description() {
    return this.customViewForm.get('description');
  }
  get ageGradeInstrumentId() {
    return this.customViewForm.get('ageGradeInstrumentId');
  }
  get ageGradeTemplateId() {
    return this.customViewForm.get('ageGradeTemplateId');
  }
  get displayMeasures() {
    return this.templateDomains.length > 0;
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.isView = this.id > 0;
    if (this.isView) {
      this.pageTitle = $localize `View Custom Rating View`;
      this.nextLabel = $localize `Configure Assignments`;
    }
    this.canEditRatingViews = this.permissionService.checkPermission(
      Permission.EditRatingViews
    );
    this.initForm();
    this.initData();
  }

  initForm() {
    this.customViewForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.maxLength(250)]],
      description: [null, [Validators.required, Validators.maxLength(500)]],
      ageGradeInstrumentId: [null, Validators.required],
      ageGradeTemplateId: [null, Validators.required],
    });

    this.subscriptions.add(
      this.ageGradeInstrumentId.valueChanges.subscribe((val: number) => {
        if (!this.isView) {
          this.templateDomains = [];
          this.ageGradeBaseTemplates = this.baseTemplates.filter(
            (r) => r.ageGradeInstrumentId == val
          );
        }
      })
    );

    this.subscriptions.add(
      this.ageGradeTemplateId.valueChanges.subscribe((val: number) => {
        if (!val || this.id > 0) {
          return;
        }

        this.templateService
          .getBaseMeasures(this.ageGradeInstrumentId.value, val)
          .pipe(
            take(1),
            tap((results) => {
              this.templateDomains = results;
            })
          )
          .subscribe();
      })
    );
  }

  initData() {
    this.lookups$ = combineLatest({
      ageGradeInstruments: this.lookupService.getAgeGradeInstruments(
        this.selectedStateId
      ),
      baseTemplates: this.templateService.getBaseTemplates(
        this.selectedStateId
      ),
    });

    this.subscriptions.add(
      this.lookups$.subscribe((results) => {
        this.ageGradeInstruments = results['ageGradeInstruments'];
        this.baseTemplates = results['baseTemplates'].filter(
          (r) => r.name != 'N/A'
        );
      })
    );

    if (this.id > 0) {
      this.templateService
        .getCustomTemplateById(this.id)
        .pipe(
          take(1),
          tap((result) => {
            this.customViewForm.setValue({
              title: result.title,
              description: result.description,
              ageGradeInstrumentId: result.ageGradeInstrumentId,
              ageGradeTemplateId: result.ageGradeTemplateId,
            });
            this.customTemplate = result;
            this.templateDomains = result.measures;
            this.customViewForm.disable();
          })
        )
        .subscribe();

      this.templateEnrollmentService
        .getCustomTemplateEnrollmentById(this.id)
        .pipe(
          take(1),
          tap((results) => {
            this.customAssignments = results;
            this.hasAssignments = this.customAssignments.length > 0;
            this.siteAssignments = results.filter((r) => r.siteId > 0);
            this.classAssignments = results.filter((r) => r.classId > 0);
            this.studentAssignments = results.filter((r) => r.studentId > 0);
          })
        )
        .subscribe();
    }
  }

  reassign() : void {   
    const modalRef = this.dialog.open(ReassignModalComponent, {
      data: this.customViewForm.value
    });
    modalRef.componentInstance.formData.subscribe((formData: any) => {
      this.title.setValue(formData.title);
      this.description.setValue(formData.description);
      this.submitForm(true);
      modalRef.close();
    });
  }

  submitForm(fromNext?: boolean) {
    if (this.customViewForm.invalid) {
      this.toast.error($localize `Please enter all required fields.`);
      return;
    }

    let extraMeasures: number[] = [];
    this.templateDomains.forEach((domain) => {
      domain.measures.forEach((m) => {
        if (m.isSelected != m.isRequired) {
          extraMeasures.push(m.id);
        }
      });
    });

    if (extraMeasures.length == 0) {
      this.toast.error($localize `There's no extra measures selected. `);
      return;
    }

    const payload = {
      ...this.customViewForm.value,
      agencyId: this.selectedAgencyId,
      measureIds: extraMeasures,
    };

    this.templateService
      .saveCustomTemplate(payload)
      .pipe(
        take(1),
        tap((result: number) => {
          const nextUrl = fromNext
            ? `/config/custom-rating-view/${result}/assignment`
            : '/config/rating-views';
          this.router.navigateByUrl(nextUrl, {
            state: {
              agencyId: this.selectedAgencyId,
              stateId: this.selectedStateId,
            },
          });
        })
      )
      .subscribe();
  }

  checkDomainSelected(domain: IBaseTemplateDomain) {
    let nonRequired = domain.measures.filter((d) => !d.isRequired);
    nonRequired.forEach((n) => (n.isSelected = domain.isAllSelected));
  }

  checkDomainAllSelected(domain: IBaseTemplateDomain) {
    domain.isAllSelected =
      domain.measures.filter((d) => !d.isSelected).length == 0;
  }

  next() {
    if (this.id) {
      this.router.navigateByUrl(
        `/config/custom-rating-view/${this.id}/assignment`,
        {
          state: {
            agencyId: this.selectedAgencyId,
            stateId: this.selectedStateId,
          },
        }
      );
    } else {
      this.submitForm(true);
    }
  }

  cancel(): void {
    this.router.navigateByUrl(`/config/rating-views`);
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions?.unsubscribe();
  }
}
