import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { combineLatest, Observable,  Subscription, take } from 'rxjs';
import { LookupService } from '@core/services/lookup.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import { IInitialAssessmentToolType, IOutcome, ISelectBoolean } from '@core/interfaces/iselectable';
import {
  IAgeGradeInstrument,
  IAgeGradeEnrollmentProgramFilter,
} from '@core/interfaces/iage-grade-instrument';
import { ILanguage } from '@core/interfaces/ilanguage';
import { IDistrictOfLiability } from '@core/interfaces/idistrict-of-liability';
import { IAdaptationType } from '@core/interfaces/iadaptation-type';
import { IEthnicity } from '@core/interfaces/iethnicity';
import { ICounty } from '@core/interfaces/icounty';
import { AuthService } from '@core/services/auth.service';
import { IDisability } from '@core/interfaces/idisability';
import { MatDialog } from '@angular/material/dialog';
import { ComponentTypeTable } from '@core/enums/table';
import {
  IStudentClassEnrollmentDetails,
  IStudentDuplicateValidationDto,
  IStudentUpdateDto,
} from '@core/interfaces/istudent';
import { IGender } from '@core/interfaces/igender';
import { ITuitionFee } from '@core/interfaces/ituition-fee';
import { IIepPlan } from '@core/interfaces/iiep-plan';
import { SelectType } from '@core/enums/select';
import {
  formatStringsToNumberArr,
  formatDatesToString,
  replaceWhitespace,
} from '@core/services/helper.service';
import { AgeGradeInstrument } from '@core/enums/age-grade-instruments';
import { EnrollmentProgram } from '@core/enums/enrollment-programs';
import {
  StudentDemographic,
  StudentDemographicEthnicity,
} from '@core/enums/student-demographic-form';
import { DemographicSections } from '@core/enums/demographic-sections';
import { DataType, FieldType } from '@core/enums/data-type';
import { StateService } from '@core/services/state.service';
import { ICustomFieldValue } from '@core/interfaces/icustom-field-value';
import { AssignChildComponent } from '@views/user/add-new-child/assign-child/assign-child.component';
import { States } from '@core/enums/states';
import { IepPlan, IepPlanValues } from '@core/enums/iep-plan';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { SelectOutcomeComponent } from '@shared/components/dropdowns/select-outcome/select-outcome.component';
import { IExtensiveSupport } from '@core/interfaces/iextensive-support';

@Component({
  selector: 'drdp-add-view-edit-child',
  templateUrl: './add-view-edit-child.component.html',
  styleUrls: ['./add-view-edit-child.component.scss'],
})
export class AddViewEditChildComponent implements OnInit {
  @ViewChild('outcome3Child') outcome3Child: SelectOutcomeComponent | undefined;
  private subscriptions = new Subscription();
  @Output() returnToView = new EventEmitter();

  get firstName() {
    return this.addChildForm.get('firstName');
  }
  get middleInitial() {
    return this.addChildForm.get('middleInitial');
  }
  get lastName() {
    return this.addChildForm.get('lastName');
  }
  get ssid() {
    return this.addChildForm.get('ssid');
  }
  get dob() {
    return this.addChildForm.get('dob');
  }
  get genderId() {
    return this.addChildForm.get('genderId');
  }
  get languageIds() {
    return this.addChildForm.get('languageIds');
  }
  get eld() {
    return this.addChildForm.get('eld');
  }
  get iepPlanId() {
    return this.addChildForm.get('iepPlanId');
  }
  get dualIepPlanId() {
    return this.addChildForm.get('dualIepPlanId');
  }
  get localId() {
    return this.addChildForm.get('localId');
  }
  get countyOfProgramId() {
    return this.addChildForm.get('countyOfProgramId');
  }
  get countyOfResidenceId() {
    return this.addChildForm.get('countyOfResidenceId');
  }
  get ageGradeInstrumentId() {
    return this.addChildForm.get('ageGradeInstrumentId');
  }
  get enrollmentProgramIds() {
    return this.addChildForm.get('enrollmentProgramIds');
  }
  get enrollmentStartDate() {
    return this.addChildForm.get('enrollmentStartDate');
  }
  get enrollmentEndDate() {
    return this.addChildForm.get('enrollmentEndDate');
  }
  get dualEnrollmentProgramIds() {
    return this.addChildForm.get('dualEnrollmentProgramIds');
  }
  get dualEnrollmentStartDate() {
    return this.addChildForm.get('dualEnrollmentStartDate');
  }
  get dualEnrollmentEndDate() {
    return this.addChildForm.get('dualEnrollmentEndDate');
  }
  get seEntryDate() {
    return this.addChildForm.get('seEntryDate');
  }
  get seAnticipateExitDate() {
    return this.addChildForm.get('seAnticipateExitDate');
  }
  get seExitDate() {
    return this.addChildForm.get('seExitDate');
  }
  get ethnicity() {
    return this.addChildForm.get('ethnicityIds');
  }
  get latino() {
    return this.addChildForm.get('latino');
  }
  get other() {
    return this.addChildForm.get('other');
  }
  get programType() {
    return this.addChildForm.get('programType');
  }
  get selpaId() {
    return this.addChildForm.get('selpaId');
  }
  get initialAssessmentToolId() {
    return this.addChildForm.get('initialAssessmentToolId');
  }
  get outcome1Id() {
    return this.addChildForm.get('outcome1Id');
  }
  get outcome2Id() {
    return this.addChildForm.get('outcome2Id');
  }
  get outcome3Id() {
    return this.addChildForm.get('outcome3Id');
  }
  get dualSelpaId() {
    return this.addChildForm.get('dualSelpaId');
  }
  get tuitionFeeId() {
    return this.addChildForm.get('tuitionFeeId');
  }
  get districtOfLiabilityId() {
    return this.addChildForm.get('districtOfLiabilityId');
  }
  get dualPrimaryDisabilityId() {
    return this.addChildForm.get('dualPrimaryDisabilityId');
  }
  get dualSeEntryDate() {
    return this.addChildForm.get('dualSeEntryDate');
  }
  get dualSeAnticipateExitDate() {
    return this.addChildForm.get('dualSeAnticipateExitDate');
  }
  get dualInitialAssessmentToolId() {
    return this.addChildForm.get('dualInitialAssessmentToolId');
  }
  get dualOutcome1Id() {
    return this.addChildForm.get('dualOutcome1Id');
  }
  get dualOutcome2Id() {
    return this.addChildForm.get('dualOutcome2Id');
  }
  get dualOutcome3Id() {
    return this.addChildForm.get('dualOutcome3Id');
  }
  get dualDistrictOfLiabilityId() {
    return this.addChildForm.get('dualDistrictOfLiabilityId');
  }
  get dualExtensiveSupportId() {
    return this.addChildForm.get('dualExtensiveSupportId');
  }
  get districtId() {
    return this.districtOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => (this.isView ? dol.districtOfLiabilityName : dol.id) === this.districtOfLiabilityId.value)?.districtId || ''
      : '';
  }
    get sauId() {
    return this.districtOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => (this.isView ? dol.districtOfLiabilityName : dol.id) === this.districtOfLiabilityId.value)?.sauId || ''
      : '';
  }
  get dualDistrictId() {
    return this.dualDistrictOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => (this.isView ? dol.districtOfLiabilityName : dol.id) === this.dualDistrictOfLiabilityId.value)?.districtId || ''
      : '';
  }
    get dualSauId() {
    return this.dualDistrictOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => (this.isView ? dol.districtOfLiabilityName : dol.id) === this.dualDistrictOfLiabilityId.value)?.sauId || ''
      : '';
  }

  get drdpId() {
     return this.addChildForm.get('drdpId');
  }

  public get ComponentTypeTable() {
    return ComponentTypeTable;
  }
  @Input() componentType?: number;
  @Input() childData?: any;
  currentSSID!: string;
  currentLocalID!: string;
  enrollmentProgramPlaceholder: string =$localize `Select Child Age/Grade Instrument`;
  isView?: boolean;
  isAdd?: boolean;
  isEdit?: boolean;
  getFormSelects$: Observable<any> | undefined;
  updateProgramTypesSelect$: Observable<any> | undefined;
  submitBtnLabel: string = history.state.enrollDroppedChild
    ? $localize `Save and Enroll`
    : $localize `Save Changes`;
  required: string = $localize `Field required.`;

  yesOrNo: ISelectBoolean[] = [];
  ethnicities: IEthnicity[] = [];
  languagesList: ILanguage[] = [];
  counties: ICounty[] = [];
  districtsOfLiability: IDistrictOfLiability[] = [];
  iepPlans: IIepPlan[] = [];
  adaptations: IAdaptationType[] = [];
  enrollmentProgramTypes!: IAgeGradeEnrollmentProgramFilter[];
  enrollmentProgramTypesFiltered!: IAgeGradeEnrollmentProgramFilter[];
  ageGradeInstruments: IAgeGradeInstrument[] = [];
  disabilities: IDisability[] = [];
  formattedDob?: any;
  formattedEthnicities?: any;
  formattedLanguages?: any = [];
  genders: IGender[] = [];
  tuitionFee: ITuitionFee[] = [];
  stateConfig: IStateConfiguration[] | null = null;
  customStateConfig: IStateConfiguration[] | null = null;
  customFieldCollection: ICustomFieldValue[] = [];
  extensiveSupports: IExtensiveSupport[] = [];
  selectEthnicity = SelectType.Ethnicities;
  iepPlan = IepPlan;
  isDistrictLiabilityRequired = false;
  addChildForm: FormGroup | any;
  userStateId: number = 0;
  otherRequired: boolean = false;
  EnrollmentProgram = EnrollmentProgram;
  AgeGradeInstrument = AgeGradeInstrument;
  ssidLabel = $localize `Statewide Student Identifier (10-Digit SSID)`;
  ssidPatternErrorMsg: string = $localize `SSID must be 10 numeric digits.`;
  ssidLocalIdReqErrorMsg: string = $localize `Either the SSID or the Agency/District ID need to be filled out.`;
  ssidmaxLength = 10;
  agencySidLabel = $localize `Agency/District Student Identifier (Agency/District)`;
  specialEdInfo = {
    isPrimaryDisabilityEnabled: false,
    isDistrictLiabilityEnabled: false,
    isSpecialEdEntryEnabled: false,
    isSpecialEdExitEnabled: false,
    isAnticipatedDateEnabled: false,
    isSelpaEnabled: false,
    isInitialAssessmentToolEnabled: false,
    isOutcome1Enabled: false,
    isOutcome2Enabled: false,
    isOutcome3Enabled: false,
  };
  customFieldMap = new Map();
  customFieldAnsCollection?: ICustomFieldValue[] = [];

  duplicateStudentValidationPayload!: IStudentDuplicateValidationDto;
  isStudentDuplicate!: boolean;
  inputType: string = this.isView ? 'text' : 'date';
  chosenCountyOfProgram: string = '';
  chosenCountyOfResidence: string = '';
  isEthnicityDisabled = false;
  isLoading = true;
  isNewHampshire = false;
  ssidErrorMsg = $localize `Duplicate SSID found.`;
  withdrawlInfo =  $localize `In order to set the Agency Withdrawal date for the child, please withdraw the child from your agency in the Manage Enrollment page. The date will populate once the child is withdrawn.`;
  agencyEnrollmentInfo = $localize `The day the child was enrolled with your agency.`;
  bypassDualEnrollment = this.permissionService.checkPermission(Permission.PowerUserViewDualEnrollments);
  canViewDualEnrollment = this.permissionService.checkPermission(Permission.ViewDualEnrollments);
  classEnrollments?: any;
  isPrimaryAgency = false;
  isDualEnrollment: boolean = false;
  isPilotEnrollment: boolean = false;
  hasFollowUp: boolean = false;
  selectedDisability?: string;
  canViewDualSped: boolean = false;
  primaryDisabilityReq: boolean = false;
  userAgencyId = this.authService.getCurrentUser().agencyId;
  specialEdExitInfo: string = $localize `In order to set the Special Education Exit Date for child, please exit the child from the Manage Enrollment > Classrooms ` +
    $localize `page. The date will populate once the child's exit date is saved.`;

  ageGradeInstrumentWarning: string = $localize `Warning - changing the Child's Age/Grade Instrument will hide all evidence saved to the child's Portfolio in their ` + 
    $localize `previous Age/Grade Instrument. If needed, please save the child's Portfolio evidence prior to changing their demographic Age/Grade Instrument.`
  public get studentDemographics() {
    return StudentDemographic;
  }
  public get demographicSections() {
    return DemographicSections;
  }
  public get fieldType() {
    return FieldType;
  }
  public get dataType() {
    return DataType;
  }
  public get ethnicityvalues() {
    return StudentDemographicEthnicity;
  }


  constructor(
    private fb: FormBuilder,
    public toastService: ToastService,
    private lookupService: LookupService,
    public studentService: StudentService,
    public authService: AuthService,
    private stateService: StateService,
    public modal: MatDialog,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.isView = this.componentType === this.ComponentTypeTable.View;
    this.isEdit = this.componentType === this.ComponentTypeTable.Edit;
    this.isAdd = this.componentType === this.ComponentTypeTable.Add;
    this.inputType = this.isView ? 'text' : 'date';
    this.userStateId = this.authService.getUserStateId();
    this.isNewHampshire = this.userStateId == States.newHampshire;
    this.getFormSelects$ = combineLatest({
      ethnicities: this.lookupService.getEthnicities(this.userStateId),
      languagesList: this.lookupService.getLanguages(),
      counties: this.lookupService.getCountyList(),
      districtsOfLiability: this.lookupService.getDistrictsOfLiability(),
      iepPlans: this.lookupService.getIepPlans(),
      adaptations: this.lookupService.getAdaptationTypes(),
      ageGradeInstruments: this.lookupService.getAgeGradeInstruments(
        this.userStateId
      ),
      disabilities: this.lookupService.getDisabilities(),
      genders: this.lookupService.getGenders(this.userStateId), // needs to filter based on state config
      tuitionFee: this.lookupService.getTuitionFees(),
      yesOrNo: this.lookupService.getBasicSelects(),
      enrollmentProgramTypes: this.lookupService.getAgeGradeEnrollmentFilters(),
      stateConfig: this.stateService.getStateConfigurations(this.userStateId),
      customConfigs: this.stateService.getCustomStateConfigurations(
        this.userStateId
      ),
      studentCustomFields: this.studentService.getStudentCustomFields(
        this.childData.drdpId
      ),
      extensiveSupports: this.lookupService.getExtensiveSupports()
    });
    this.initializeForm();
    this.subscriptions.add(
      this.getFormSelects$.subscribe((res: any) => {
        this.ethnicities = res['ethnicities'];
        this.languagesList = res['languagesList'];
        this.counties = res['counties'];
        this.districtsOfLiability = res['districtsOfLiability'];
        this.iepPlans = res['iepPlans'];
        this.adaptations = res['adaptations'];
        this.ageGradeInstruments = res['ageGradeInstruments'];
        this.disabilities = res['disabilities'];
        this.genders = res['genders'];
        this.tuitionFee = res['tuitionFee'];
        this.yesOrNo = res['yesOrNo'];
        this.enrollmentProgramTypes = res['enrollmentProgramTypes'];
        this.stateConfig = res['stateConfig'];
        this.customStateConfig = res['customConfigs'];
        this.customFieldCollection = res['studentCustomFields'];
        this.extensiveSupports = res['extensiveSupports']

        if (this.componentType !== ComponentTypeTable.Add)
          this.loadFormValues();

        this.checkSpecialEdInfo();
        this.buildCustomConfigFormControl();

        // set SSID & LocalId custom labels
        const ssid = this.stateConfig?.find(
          (x: any) => x.columnName === this.studentDemographics.SSID
        );
        this.ssidLabel = ssid?.label ? ssid?.label : this.ssidLabel;
        const localId = this.stateConfig?.find(
          (x: any) => x.columnName === this.studentDemographics.AgencySID
        );
        this.agencySidLabel = localId?.label
          ? localId?.label
          : this.agencySidLabel;

        this.ssidErrorMsg = this.isNewHampshire
          ? $localize `Duplicate SASID found.`
          : this.ssidErrorMsg;

        this.ssidmaxLength = ssid?.maxLength! ?? this.ssidmaxLength;

        this.ssidPatternErrorMsg = `${this.isNewHampshire ? 'SASID' : 'SSID'}` + $localize `must be` + `${this.ssidmaxLength }` + $localize `numeric digits.`;

        this.ssidLocalIdReqErrorMsg = $localize `Either the` + `${this.isNewHampshire ? 'SASID' : 'SSID'}` + $localize `or the Agency/District ID need to be filled out.`

        // Get SSID && LocalId Max Length
        if (ssid?.maxLength) {
          this.ssidmaxLength = ssid.maxLength;
          this.ssid.setValidators([Validators.pattern(new RegExp(`^\\d{${this.ssidmaxLength}}$`))]);
          this.ssid.updateValueAndValidity();
        }
      })
    );
  }

  initializeForm(): void {
    this.addChildForm = this.fb.group({
      // ========== Child Information ==========
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      middleInitial: [null, Validators.maxLength(2)],
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      ssid: [
        null,
        {
          validators: [Validators.required, Validators.pattern(/^\d{10}$/)],
          updateOn: 'blur',
        },
      ],
      drdpId: [null],
      localId: [
        null,
        {
          validators: [Validators.pattern('^[0-9]*$')],
          updateOn: 'blur',
        },
      ],
      ageGradeInstrumentId: [null, [Validators.required]],
      dob: [null, [Validators.required]],
      genderId: [null, [Validators.required]],
      socialEconomic: [0],
      // ========== Race & Ethnicity ==========
      latino: [null, [Validators.required]],
      ethnicityIds: [null, [Validators.required]],
      // ========== Language ==========
      languageIds: [null, [Validators.required]],
      eld: [null, [Validators.required]],
      // ========== Program ==========
      enrollmentProgramIds: [null, Validators.required],
      dualEnrollmentProgramIds: [null],
      other: [null],
      enrollmentStartDate: [null, [Validators.required]],
      enrollmentEndDate: [null],
      dualEnrollmentStartDate: [null],
      dualEnrollmentEndDate: [null],
      countyOfProgramId: [null],
      countyOfResidenceId: [null],
      tuitionFeeId: [null],
      // ========== Special Education ==========
      iepPlanId: [null, [Validators.required]],
      dualIepPlanId: [null],
      primaryDisabilityId: [null],
      districtOfLiabilityId: [null],
      adaptationIds: [null],
      dualAdaptationIds: [null],
      seEntryDate: [null],
      seAnticipateExitDate: [null],
      seExitDate: [null],
      selpaId: [null],
      dualSelpaId: [null],
      initialAssessmentToolId: [null],
      outcome1Id: [null],
      outcome2Id: [null],
      outcome3Id: [null],
      dualPrimaryDisabilityId: [null],
      dualSeEntryDate: [null],
      dualSeAnticipateExitDate: [null],
      dualInitialAssessmentToolId: [null],
      dualOutcome1Id: [null],
      dualOutcome2Id: [null],
      dualOutcome3Id: [null],
      dualDistrictOfLiabilityId: [null],
      dualExtensiveSupportId: [null]
    });
  }

  loadFormValues(): void {
    this.onFormChanges();
    this.chosenCountyOfProgram = this.childData?.countyName;
    this.chosenCountyOfResidence = this.childData?.residenceCountyName;
    this.currentSSID = this.childData?.ssid;
    this.addChildForm.patchValue({
      // ========== Child Information ==========
      firstName: this.childData?.firstName,
      middleInitial: this.childData?.middleInitial,
      lastName: this.childData?.lastName,
      drdpId: this.childData?.drdpId,
      ageGradeInstrumentId: this.isView
        ? this.childData?.ageGradeInstrumentName
        : this.childData?.ageGradeInstrumentId,
      dob: formatDatesToString(this.childData?.dob),
      genderId: this.isView
        ? this.childData?.genderName
        : this.childData?.genderId,
      socialEconomic: this.childData?.socialEconomic,
      // ========== Race & Ethnicity ==========
      latino: this.isView
        ? this.returnYesOrNo(this.childData?.latino)
        : [this.childData?.latino],
      ethnicityIds: this.isView
        ? this.childData?.ethnicities
        : formatStringsToNumberArr(this.childData?.ethnicityIds),
      // ========== Language ==========
      languageIds: this.isView
        ? this.childData?.languages
        : formatStringsToNumberArr(this.childData?.languageIds),
      eld: this.isView
        ? this.returnYesOrNo(this.childData?.eld)
        : [this.childData?.eld],
      // ========== Program ==========
      enrollmentProgramIds: this.isView
        ? this.childData?.enrollmentProgramNames
        : formatStringsToNumberArr(this.childData?.enrollmentProgramIds),
      dualEnrollmentProgramIds: this.isView
        ? this.childData?.dualEnrollmentProgramNames
        : formatStringsToNumberArr(this.childData?.dualEnrollmentProgramIds),
      other: this.childData?.otherPrograms,
      enrollmentStartDate: history.state.enrollDroppedChild ? null : this.childData?.enrollmentStartDate,
      enrollmentEndDate: history.state.enrollDroppedChild ? null : this.childData?.enrollmentEndDate,
      dualEnrollmentStartDate: history.state.enrollDroppedChild ? null : this.childData?.dualEnrollmentStartDate,
      dualEnrollmentEndDate: history.state.enrollDroppedChild ? null : this.childData?.dualEnrollmentEndDate,
      countyOfProgramId: this.childData?.countyId,
      countyOfResidenceId: this.childData?.countyOfResidenceId,
      tuitionFeeId: this.isView
        ? this.childData?.tuitionFeeName
        : this.childData?.tuitionFeeId,
      // ========== Special Education ==========
      iepPlanId: this.isView
        ? this.childData?.iepPlanName
        : this.childData?.iepPlanId,
      dualIepPlanId: this.isView
        ? this.childData?.dualEnrollmentIepPlanName
        : this.childData?.dualEnrollmentIepPlanId,
      primaryDisabilityId: this.isView
        ? this.childData?.primaryDisabilityName
        : this.childData?.primaryDisabilityId,
      districtOfLiabilityId: this.isView
        ? this.childData?.districtOfLiabilityName
        : this.childData?.districtOfLiabilityId,
      adaptationIds: this.isView
        ? this.childData?.adaptations
        : formatStringsToNumberArr(this.childData?.adaptationIds),
      dualAdaptationIds: this.isView
        ? this.childData?.dualAdaptations
        : formatStringsToNumberArr(this.childData?.dualAdaptationIds),
      seEntryDate: this.childData?.seEntryDate,
      seAnticipateExitDate: this.childData?.seAnticipateExitDate,
      seExitDate: this.childData?.seExitDate,
      selpaId: this.childData?.selpaId,
      dualSelpaId: this.childData?.dualEnrollmentSelpaId,
      initialAssessmentToolId: this.childData?.initialAssessmentToolId,
      outcome1Id: this.childData?.outcome1Id,
      outcome2Id: this.childData?.outcome2Id,
      outcome3Id: this.childData?.outcome3Id,
      dualPrimaryDisabilityId: this.isView
        ? this.childData?.dualPrimaryDisabilityName
        : this.childData?.dualPrimaryDisabilityId,
      dualSeEntryDate: this.childData?.dualSeEntryDate,
      dualSeAnticipateExitDate: this.childData?.dualSeAnticipateExitDate,
      dualInitialAssessmentToolId: this.isView
        ? this.childData?.dualInitialAssessmentToolName
        : this.childData?.dualInitialAssessmentToolId,
      dualOutcome1Id: this.isView
        ? this.childData?.dualOutcome1Name
        : this.childData?.dualOutcome1Id,
      dualOutcome2Id: this.isView
        ? this.childData?.dualOutcome2Name
        : this.childData?.dualOutcome2Id,
      dualOutcome3Id: this.isView
        ? this.childData?.dualOutcome3Name
        : this.childData?.dualOutcome3Id,
      dualDistrictOfLiabilityId: this.isView
        ? this.childData?.dualDistrictOfLiabilityName
        : this.childData?.dualDistrictOfLiabilityId,
      dualExtensiveSupportId: this.isView
        ? this.childData?.dualExtensiveSupportName
        : this.childData?.dualExtensiveSupportId
    });

    if (this.childData?.ssid) {
      this.addChildForm.patchValue({
        ssid: this.childData?.ssid,
      });
    }

    if (this.childData?.localId) {
      this.addChildForm.patchValue({
        localId: this.childData?.localId,
      });
    }

    if (this.currentSSID) {
      this.localId.setValidators([]);
      this.localId.updateValueAndValidity();
    }

    if (this.currentLocalID) {
      this.ssid.setValidators([Validators.pattern(/^\d{10}$/)]);
      this.ssid.updateValueAndValidity();
    }
    this.getEnrollmentDetails(this.drdpId.value);
    this.selectedDisability = this.childData?.dualPrimaryDisabilityName;
    this.hasFollowUp = this.childData?.hasFollowUp;
    this.isLoading = false;
  }

  getEnrollmentDetails(studentId: number): void {
    if (studentId) {
      this.studentService
      .getClassroomEnrollmentDetails(studentId)
      .pipe(
        take(1)
      )
      .subscribe((res: any) => {
        this.isPrimaryAgency = res.some((enrollment: any) => !enrollment.isDualEnrollment);
        this.isDualEnrollment = res.some((enrollment: any) => enrollment.isDualEnrollment);
        this.isPilotEnrollment = res.some((enrollment: any) => enrollment.isPilotEnrollment);
      });
      this.canViewDualSped = this.bypassDualEnrollment || this.isDualEnrollment || this.isPilotEnrollment;
      this.primaryDisabilityReq = this.dualIepPlanId == this.iepPlan.Yes && this.isPilotEnrollment;
    }
  }

  validateSSID() {
    if (this.ssid.value) {
      this.studentService
        .checkDuplicateStringSSID(this.ssid.value, this.userStateId)
        .pipe(take(1))
        .subscribe((isDuplicate: boolean) => {
          if (isDuplicate) {
            this.ssid.setErrors({ duplicateSSID: true });
            this.localId.setValidators([Validators.required]);
          } else {
            this.localId.setValidators([]);
          }
          this.localId.updateValueAndValidity();
        });
    }
  }

  returnYesOrNo(value: number) {
    return value == 0 ? $localize`No` : value == 1 ? $localize`Yes` : $localize`Unknown`;
  }

  handleSubmit(event?: any): void {
    if (!this.ssid.value && !this.localId.value) {
      this.ssid.setValidators([Validators.required]);
      this.ssid.updateValueAndValidity();
    }

    if (!this.addChildForm.valid) {
      this.addChildForm.updateValueAndValidity();
      this.toastService.error($localize `Please complete the form before submitting.`);
      return;
    }

    this.updateStudent();
  }

  updateStudent(): void {
    let customUpdatedValues: ICustomFieldValue[] = [];
    this.customFieldMap.forEach((config: ICustomFieldValue) => {
      customUpdatedValues.push(config);
    });

    this.firstName?.setValue(replaceWhitespace(this.firstName.value));
    this.lastName?.setValue(replaceWhitespace(this.lastName.value));
    let payload: IStudentUpdateDto = this.addChildForm.value;
    payload.eld = this.eld.value.length ? this.eld.value[0] : this.eld.value;
    payload.latino = this.latino.value.length
      ? this.latino.value[0]
      : this.latino.value;
    payload.customFields = customUpdatedValues;
    if (this.isEdit) {
      this.studentService
        .updateStudent(payload, $localize `Student successfully updated.`)
        .subscribe((res: any) => {
          if (res) {
            this.isEdit = !this.isEdit;
            this.isView = !this.isView;
            this.returnToView.emit();
          }
          if (history.state.enrollDroppedChild) {
            this.openEnrollDroppedChildModal();
          }
          if (this.childData.ageGradeInstrumentId != payload.ageGradeInstrumentId) {
            this.toastService.error(this.ageGradeInstrumentWarning);
          }
        });
    }
  }

  openEnrollDroppedChildModal(): void {
    var data = this.addChildForm.value;
    data.enrollDroppedChild = true;
    const modalRef = this.modal.open(AssignChildComponent, { data: data });
    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.toastService.success($localize `Student Successfully Saved and Enrolled`);
        window.location.reload();
      }
    });
  }

  onFormChanges(): void {
    this.ageGradeInstrumentId.valueChanges.subscribe((val: any) => {
      this.enrollmentProgramIds.reset();
      this.enrollmentProgramTypesFiltered = [];
      this.enrollmentProgramPlaceholder = $localize `Select all that apply`;
      this.enrollmentProgramTypesFiltered =
      this.enrollmentProgramTypes?.filter((ept: IAgeGradeEnrollmentProgramFilter) => {
              return ept.ageGradeInstrumentId === val;
            });
      this.enrollmentProgramIds.setValue(formatStringsToNumberArr(this.childData?.enrollmentProgramIds));
      this.dualEnrollmentProgramIds.setValue(
        formatStringsToNumberArr(this.childData?.dualEnrollmentProgramIds)
      );
    });

    this.enrollmentProgramIds.valueChanges.subscribe((val: any) => {
      if (
        val &&
        (val.includes(this.EnrollmentProgram.other) ||
          val.includes(EnrollmentProgram.otherNH))
      ) {
        this.other.setValidators([Validators.required]);
        this.other.updateValueAndValidity();
        this.otherRequired = true;
        return;
      }

      this.other.reset();
      this.other.setValidators([]);
      this.other.updateValueAndValidity();
      this.otherRequired = false;
    });

    this.ssid.valueChanges.subscribe((val: any) => {
      if (this.currentSSID == val) {
        this.localId.setValidators([]);
        this.localId.updateValueAndValidity();
        return;
      }
      if (this.ssid.valid) {
        this.validateSSID();
      } else {
        this.localId.setValidators([Validators.required]);
        this.localId.updateValueAndValidity();
      }
    });

    this.localId.valueChanges.subscribe((val: any) => {
      var ssidPattern = new RegExp(`^\\d{${this.ssidmaxLength}}$`);
      if (this.currentLocalID == val) return;
      this.currentLocalID = val;
      if (this.localId.valid) {
        if (this.currentLocalID) {
          this.ssid.setValidators([Validators.pattern(ssidPattern)]);
        } else {
          this.ssid.setValidators([
            Validators.required,
            Validators.pattern(ssidPattern),
          ]);
        }
        this.ssid.updateValueAndValidity();
      } else {
        this.ssid.setValidators([
          Validators.required,
          Validators.minLength(this.ssidmaxLength),
          Validators.pattern(ssidPattern),
        ]);
        this.ssid.updateValueAndValidity();
      }
    });

    if (this.ssid.invalid) this.ssid.markAsTouched();
    if (this.localId.invalid) this.localId.markAsTouched();
    if (this.middleInitial) this.middleInitial.markAsTouched();

    this.dualIepPlanId.valueChanges.subscribe((val: any) => {
      const isIepPlanYes = val == this.iepPlan.Yes;

      if (this.specialEdInfo.isDistrictLiabilityEnabled) {
        if (isIepPlanYes) {
          this.dualDistrictOfLiabilityId.setValidators([Validators.required]);
          this.dualDistrictOfLiabilityId.updateValueAndValidity();
        }
        else {
          this.dualDistrictOfLiabilityId.setValidators([]);
          this.dualDistrictOfLiabilityId.updateValueAndValidity();
        }
      }
      else if (this.isPilotEnrollment) {
        this.primaryDisabilityReq = isIepPlanYes;
        this.selectedDisability = isIepPlanYes ? 'Primary Disability' : this.selectedDisability;
        this.hasFollowUp = !isIepPlanYes;
        if (!isIepPlanYes) {
          this.dualPrimaryDisabilityId.setValue(null);
          this.dualExtensiveSupportId.setValue(null);
        }
      }
    });
  }

  resetAddChildForm(): void {
    window.location.reload();
    if (this.componentType === ComponentTypeTable.Add)
      this.toastService.success($localize `The form has been reset.`);
  }

  checkSpecialEdInfo(): void {
    if (this.stateConfig) {
      this.stateConfig.forEach((config: IStateConfiguration) => {
        switch (config.columnName) {
          case this.studentDemographics.PrimaryDisability:
            this.specialEdInfo.isPrimaryDisabilityEnabled = config.isActive;
            break;
          case this.studentDemographics.DistrictLiability:
            this.specialEdInfo.isDistrictLiabilityEnabled = config.isActive;
            if (this.isNewHampshire &&
              (this.childData?.iepPlanName === IepPlanValues.Yes || this.iepPlanId.value === this.iepPlan.Yes)) {
              this.isDistrictLiabilityRequired = true;
              this.districtOfLiabilityId.setValidators(Validators.required);
              this.districtOfLiabilityId.updateValueAndValidity();
            }
            break;
          case this.studentDemographics.SpecialEdEntryDate:
            this.specialEdInfo.isSpecialEdEntryEnabled = config.isActive;
            this.setSpecialEdEntryDateValidation();
            break;
          case this.studentDemographics.AnticipatedDateExit:
            this.specialEdInfo.isAnticipatedDateEnabled = config.isActive;
            break;
          case this.studentDemographics.DateExit:
            this.specialEdInfo.isSpecialEdExitEnabled = config.isActive;
            break;
          case this.studentDemographics.Selpa:
            this.specialEdInfo.isSelpaEnabled = config.isActive;
            break;
          case this.studentDemographics.initialAssessmentToolId:
            this.specialEdInfo.isInitialAssessmentToolEnabled = config.isActive;
            break;
          case this.studentDemographics.outcome1Id:
            this.specialEdInfo.isOutcome1Enabled = config.isActive;
            break;
          case this.studentDemographics.outcome2Id:
            this.specialEdInfo.isOutcome2Enabled = config.isActive;
            break;
          case this.studentDemographics.outcome3Id:
            this.specialEdInfo.isOutcome3Enabled = config.isActive;
            break;
          default:
            return;
        }
      });
    }
  }

  buildCustomConfigFormControl(): void {
    if (this.customStateConfig) {
      this.customStateConfig.forEach((config: IStateConfiguration) => {
        if (config.isActive) {
          let formControlExist = false;

          if (this.customFieldCollection.length) {
            this.customFieldCollection.forEach(
              (customValue: ICustomFieldValue) => {
                if (config.id === customValue.stateDemographicConfigurationId) {
                  if (config.isRequired) {
                    this.addChildForm.addControl(
                      config.label,
                      new FormControl(null, [
                        Validators.required,
                        Validators.maxLength(config.maxLength),
                      ])
                    );
                    this.addChildForm.patchValue({
                      [config.label]: this.findCustomValue(
                        customValue,
                        config.customFieldTypeId
                      ),
                    });
                  } else {
                    this.addChildForm.addControl(
                      config.label,
                      new FormControl(
                        this.findCustomValue(
                          customValue,
                          config.customFieldTypeId
                        ),
                        Validators.maxLength(config.maxLength)
                      )
                    );
                    this.addChildForm.patchValue({
                      [config.label]: this.findCustomValue(
                        customValue,
                        config.customFieldTypeId
                      ),
                    });
                  }
                  formControlExist = true;
                }
              }
            );
          }
          if (!formControlExist) {
            if (config.isRequired) {
              this.addChildForm.addControl(
                config.label,
                new FormControl(null, [
                  Validators.required,
                  Validators.maxLength(config.maxLength),
                ])
              );
            } else {
              this.addChildForm.addControl(
                config.label,
                new FormControl(null, Validators.maxLength(config.maxLength))
              );
            }
          }
        }
      });
    }
  }

  findCustomValue(customField: ICustomFieldValue, fieldType: number): any {
    switch (fieldType) {
      case FieldType.date:
        return formatDatesToString(customField.dateValue);
      case FieldType.dropdownMultiple:
        return customField.lgCharValue.split('|');
      case FieldType.dropdownSingle:
      case FieldType.text:
        return customField.smCharValue;
      case FieldType.number:
        return customField.intValue;
      default:
        return null;
    }
  }

  buildCustomConfigObj(
    colName: string,
    configId: number,
    customFieldTypeId: number,
    event?: any
  ): void {
    if (event) {
      this.addChildForm.get(colName).setValue(event);
    }
    const ans = this.addChildForm.get(colName).value;
    const customValueExist = this.customFieldCollection.find(
      (customField: ICustomFieldValue) =>
        customField.stateDemographicConfigurationId === configId
    );
    const customField: ICustomFieldValue = {
      id: customValueExist?.id ?? 0,
      studentId: this.childData.drdpId,
      stateDemographicConfigurationId: configId,
      intValue: customFieldTypeId == FieldType.number ? ans : 0,
      smCharValue:
        customFieldTypeId === FieldType.dropdownSingle ||
        customFieldTypeId === FieldType.text
          ? ans
          : '',
      lgCharValue:
        customFieldTypeId === FieldType.dropdownMultiple ? ans.join('|') : '',
      dateValue: customFieldTypeId === FieldType.date ? ans : '',
    };
    this.customFieldMap.set(colName, customField);
  }

  handleChosenCountyOfProgram(event: any): void {
    this.chosenCountyOfProgram = event?.countyName;
    this.countyOfProgramId.setValue(event?.id);
  }

  handleChosenCountyOfResidence(event: any): void {
    this.chosenCountyOfResidence = event?.countyName;
    this.countyOfResidenceId.setValue(event?.id);
  }

  handleChosenSelpa(event: any, isPrimary: boolean): void {
    if (isPrimary) {
      this.selpaId.setValue(event?.id);
    } else {
      this.dualSelpaId.setValue(event?.id);
    }
  }

  handleChosenEthnicity(event: any): void {
    if (
      event.id === this.ethnicityvalues.Intentionally_left_blank &&
      !this.isEthnicityDisabled
    ) {
      this.isEthnicityDisabled = true;
      this.ethnicity.setValue([event.id]);
    } else if (
      event.id === this.ethnicityvalues.Intentionally_left_blank &&
      this.isEthnicityDisabled
    ) {
      this.isEthnicityDisabled = false;
    }
  }


  handleDate(event: any, controlName: string): void {
    if (event === '' || !event) {
      this.addChildForm.get(controlName).setValue(formatDatesToString(null));
    } else {
      this.addChildForm.get(controlName).setValue(formatDatesToString(event));
    }
  }

  handleEld(event: ISelectBoolean): void {
    this.eld.setValue(event?.id);
  }

  handleAgeGrade(event: IAgeGradeInstrument): void {
    this.ageGradeInstrumentId.setValue(event?.id);
  }

  handleHispanic(event: ISelectBoolean): void {
    this.latino.setValue(event?.id);
  }

  handleIep(event: IIepPlan, primary: boolean): void {
    if (primary) {
      this.iepPlanId.setValue(event?.id);

      if (this.isNewHampshire && this.specialEdInfo.isDistrictLiabilityEnabled && event?.id == this.iepPlan.Yes) {
        this.isDistrictLiabilityRequired = true;
        this.districtOfLiabilityId.addValidators([Validators.required]);
        this.districtOfLiabilityId.updateValueAndValidity();
      }
      else {
        this.isDistrictLiabilityRequired = false;
        this.districtOfLiabilityId.clearValidators();
        this.districtOfLiabilityId.updateValueAndValidity();
      }
      this.setSpecialEdEntryDateValidation();
    } else {
      console.log('disabilities',this.disabilities)
      this.dualIepPlanId.setValue(event?.id);
    }
  }

  setSpecialEdEntryDateValidation(): void {
    if (this.specialEdInfo.isSpecialEdEntryEnabled) {
      if (this.iepPlanId.value == this.iepPlan.Yes) this.seEntryDate.setValidators([Validators.required]);
      else this.seEntryDate.clearValidators();
      this.seEntryDate.updateValueAndValidity();
    }
  }

  handleOutcome(event: IOutcome, controlName: string): void {
    if (!this.addChildForm.get(controlName)) return;
    this.addChildForm.get(controlName).setValue(event?.id);
  }

  handleInitAssessment(event: IInitialAssessmentToolType, control: string): void {
    this.addChildForm.get(control).setValue(event?.id);
  }

  handlePrimaryDisability(event: any) {
    this.dualPrimaryDisabilityId.setValue(event.value.id);
    this.hasFollowUp = event.value.hasFollowUp;
    if (!this.hasFollowUp) this.dualExtensiveSupportId.setValue(null);
    this.selectedDisability = event.value.primaryDisabilityName;
  }

  deselectCheck(id: number): void {
    const currentTuitionFeeId = this.tuitionFeeId?.value;
    if (currentTuitionFeeId === id) {
      this.tuitionFeeId?.setValue(null);
    } else {
      this.tuitionFeeId?.setValue(id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['childData']['previousValue'] != undefined) {
      this.childData = changes['childData']['currentValue'];
      this.initializeForm();
      this.loadFormValues();
    }
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
