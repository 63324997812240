import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LoginAuditService } from '@core/services/login-audit.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { RoleService } from '@core/services/role.service';
import { IRoles } from '@core/interfaces/iroles';
import { formatDate } from '@angular/common';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-login-audits',
  templateUrl: './login-audits.component.html',
  styleUrls: ['./login-audits.component.scss']
})

export class LoginAuditsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchForm!: FormGroup;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize: number = 10;
  totalData:number = 0;
  pageIndex: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  roles: any;
  itemsPerPage: number = 10;
  clearDropdown = false;
  noDataMessage?: string = $localize `There are no records to display.`;

  tableColumns = [
    { columnDef: 'loginDate', header: $localize `Login Time`, type: 'fullDate', isSortable: true },
    { columnDef: 'logoutDate', header: $localize `Logout Time`, type: 'fullDate', isSortable: true },
    { columnDef: 'userEmail', header: $localize `Email`, type: 'text', isSortable: true },
    { columnDef: 'userFullName', header: $localize `Name`, type: 'text', isSortable: true },
    { columnDef: 'roles', header: $localize `Roles`, type: 'text', isSortable: true },
  ];

  get fromDate() {return this.searchForm.get('fromDate')};
  get toDate() {return this.searchForm.get('toDate')};
  get roleIds() {return this.searchForm.get('roleIds')};

  constructor(private service: LoginAuditService, private RoleService: RoleService, private fb: FormBuilder, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getRoleOptions();
    this.initializeForm();
  }
  
  initializeForm() {
    this.searchForm = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      roleIds: [null, Validators.required],
    }, { validator: [this.dateLessThan('fromDate', 'toDate'), this.dateWithinRange('fromDate', 'toDate')]} as AbstractControlOptions);
  }

  dateLessThan( fromDate: string, toDate: string) {
    return (group: FormGroup) => {
      let startDate = group.controls[fromDate].value;
      let endDate = group.controls[toDate].value;
      const searchForm = group.controls[toDate];
      if (startDate > endDate) {
        return searchForm.setErrors({invalidDates: true});
      }
      return {}
     }
  }

  dateWithinRange (fromDate: any, toDate: any) {
    return (group: FormGroup) => {
      let startDate = group.controls[fromDate]?.value;
      let endDate = group.controls[toDate]?.value;
      if (fromDate && toDate) {
        const oneYearInMs = 1000 * 60 * 60 * 24 * 365;
        const timeDiff = Math.abs(endDate?.getTime() - startDate?.getTime());
        const diffInYears = timeDiff / oneYearInMs;
  
        if (diffInYears >= 1.004) {
          return this.toDate?.setErrors({ dateRangeNotWithinOneYear: true });
        }
      }
      return {};
     }
    }

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = '';
  }

  handleRoles(event: IRoles[]): void {
    if (!event.length) {
      this.roleIds!.setValue(null);
      return;
    }
    this.roleIds!.setValue(event.map((e: any) => e.id));
  }

  submitSearch() {
    if (this.searchForm.invalid || !this.fromDate?.value && !this.toDate?.value) {
      this.toastService.warn($localize `Invalid search.`);
      return;
    }
    this.paginateAgency(true);
  }

  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res.data);
    this.totalData = res.totalData;
    this.tableData = res.data;
  }

  paginateAgency(resetPage?: boolean): void {
    const payload = {
      fromDate: formatDate(this.fromDate?.value, 'yyyy/MM/dd', 'en-US'),
      toDate: formatDate(this.toDate?.value, 'yyyy/MM/dd', 'en-US'),
      roleIds: this.roleIds?.value,
      pageIndex: resetPage ? 0 : this.paginator.pageIndex + 1,
      itemsPerPage: this.paginator.pageSize
    }
    this.service.search(payload).subscribe((res: any) => {
      if (res) {
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(res);
        if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
      }
    })
  }

  getRoleOptions() {
    this.RoleService.getAllRoles().subscribe((res:[IRoles])=> {
      this.roles = res?.map((item:IRoles) =>  {
        return { key: item.id, value: item.roleName }
      })
      this.roles.push({key: 0, value: 'ALL'});
    })
  }

  download(resetPage?: boolean) {
    if (this.searchForm.invalid || !this.fromDate?.value && !this.toDate?.value) {
      this.toastService.warn($localize `Download unavailable.`);
      return;
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const payload = {
      fromDate: formatDate(this.fromDate?.value, 'yyyy/MM/dd', 'en-US'),
      toDate: formatDate(this.toDate?.value, 'yyyy/MM/dd', 'en-US'),
      roleIds: this.roleIds?.value,
      timeZoneId: timeZone,
      pageIndex: resetPage ? 0 : this.paginator.pageIndex + 1,
      itemsPerPage: resetPage ? 10 : this.paginator.pageSize
    }

    this.service.downloadFile(payload).subscribe((res: any) => {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.body);
      a.setAttribute('download', 'login-audits.csv')
      a.click();
    });
  }

  clear() {
    const empty: any = [];
    this.initializeTableData(empty);
    this.clearDropdown = true;
    this.searchForm.reset();
    this.rerunSearchFormValidation();
    this.paginator.pageSize = 10;
    this.totalData = 0;
  }

  resetEndDate() {
    if (this.toDate && this.toDate.value) {
      this.toDate?.setValue(null);
      this.toDate?.setErrors({ dateRangeNotWithinOneYear: true });
    }
  }

  private rerunSearchFormValidation() {
    Object.values(this.searchForm.controls).forEach(control => {
      control.updateValueAndValidity();
    });
  }
}
