<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-full">
      <drdp-title>
        <span i18n>Viewing Students in </span>
        {{ classroomName || '(Classroom Name Not Found)' }}
      </drdp-title>
      <h2 class="pb-5">
        <span i18n>Rating Period:</span> 
        {{ ratingPeriodName ?? "(Rating Period Not Found)" }}
      </h2>
    </div>
    <div class="flex justify-end items-center" *ngIf="canBatchUpdateSped">
      <drdp-btn i18n type="button" additionalClasses="lg:m-2 xl:m-0 inline-block" (click)="batchSpedExit()" color="outline">Special Ed Exit Date by Batch</drdp-btn>
    </div>
  </div>

  <drdp-container *ngIf="showTable">
    <div class="w-full">
      <drdp-add-edit-table
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns"
        (iconActionItem)="onIconClick($event)"
        (actionIconClicked)="onActionItemClicked($event)"
      >
      </drdp-add-edit-table>
    </div>
  </drdp-container>
</div>
<mat-paginator
  class="mb-12"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="pageSize"
  [length]="totalData"
  [pageIndex]="pageIndex"
  (page)="paginate($event)"
>
</mat-paginator>
